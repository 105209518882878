import axiosClient from './axiosClient'

const dashboardApi = {
  getDM: () => axiosClient.get('/dashboard/getDM'),
  getPayment: () => axiosClient.get('/dashboard/getPayment'),
  getForDate: (type, names) => axiosClient.get(`dashboard/${type}.${names}`),
  saveTab: (params) => axiosClient.post(`dashboard/saveTab`, params),
  getTabs: () => axiosClient.get('/dashboard/getTabs'),
  saveExtra: (params) => axiosClient.post(`dashboard/saveExtra`, params),
  getExtra: () => axiosClient.get('/dashboard/getExtra'),
  saveBonus: (params) => axiosClient.post(`dashboard/saveBonus`, params),
  getBonus: () => axiosClient.get('/dashboard/getBonus'),
}

export default dashboardApi