import axios from 'axios';
import queryString from 'query-string'

let domain = window.location.hostname == 'localhost' ? 'aglar.in' : /(?<=\.).+/.exec(window.location.hostname)[0];
const baseUrl = 'https://api.'+domain+'/api/v1/';

const axiosServices = axios.create({
    baseURL: baseUrl,
    paramsSerializer: params => queryString.stringify({ params })
});

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

// axiosServices.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
// );

export default axiosServices;
